import { useWallet } from "use-wallet";
import ClaimingCard from "components/common/ClaimingCard";

export default function NFTClaimingPage() {
  const wallet = useWallet();
  return (
    <div className="main-container al-v">
      {wallet.isConnected() !== true ? (
        <h1 style={{ textAlign: "center" }}>Wallet not connected.</h1>
      ) : (
        <ClaimingCard></ClaimingCard>
      )}
    </div>
  );
}
