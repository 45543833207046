import React, { useState, useEffect } from "react";
import "../../assets/css/style.css";
import { useWallet } from "use-wallet";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import NftImage from "../../assets/images/nft.png";
import LeftNFT from "../../assets/images/nft1.jpg";
import RightNFT from "../../assets/images/nft2.jpg";
import { healNFTContract, healNFTMint } from "../../heal-contract";
import { dsWeb3GetContract } from "ds-lib/ds-web3";

export default function MintingCard() {
  const [mintNum, setMintNum] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const wallet = useWallet();

  const mintNumMinus = () => {
    setMintNum(mintNum - 1);
  };

  const mintNumPlus = () => {
    setMintNum(mintNum + 1);
  };

  const getTotalSupply = async () => {
    const provider = wallet._web3ReactContext.library;
    const contract = await healNFTContract(provider);
    const value = await contract.methods.totalSupply().call();
    setTotalSupply(value);
  };

  const nftMint = async () => {
    console.log(wallet.account, "Mint", mintNum);

    const provider = wallet._web3ReactContext.library;
    if (mintNum === 0) {
      toast.warning("Please add mint Number");
    } else {
      const transaction = healNFTMint(provider, wallet.account, mintNum);
      await transaction
        .then(function (recipent) {
          toast.success("Successfuly minted!");
        })
        .catch(function (e) {
          toast.error(e.message);
        });
    }
  };

  useEffect(() => {
    if (wallet) getTotalSupply();
  }, [wallet]);

  return (
    <Container>
      <Row className="title-field">
        <Col lg="12" md="12" sm="12" xs="12">
          <div className="d-flex f-center">
            <h1 className="nft-page-title">$Heal NFTs</h1>
          </div>
        </Col>
      </Row>
      <Row className="main-field">
        <Col lg="3" md="3" sm="12" xs="12" className="nft-image-field">
          <img src={LeftNFT} className="nft-image" />
        </Col>
        <Col lg="6" md="6" sm="12" xs="12">
          {/* <div className='nft-minting-card'>
            <h2 className='t-center'>0/3000</h2>
          </div> */}
          <div className="nft-minting-card">
            <p className="nft-mint-number">{totalSupply} / 1000 NFTs Minted</p>
            <p className="nft-contract-address">
              0x3a3251Bf714F808C4aEe57F7D5f618C1d2612Ff0
            </p>
            <div className="d-flex f-center">
              <Button className="opensea-btn">Opensea</Button>
            </div>
            <p className="nft-mint-price">1 Heal Nft costs 0.1Eth</p>
            <div className="d-flex f-center">
              <Button
                className="nft-mint-minus"
                disabled={mintNum === 0}
                onClick={mintNumMinus}
              >
                -
              </Button>
              {console.log("mintNumber", mintNum)}
              <Button className="nft-mint-amount">{mintNum}</Button>
              <Button className="nft-mint-plus" onClick={mintNumPlus}>
                +
              </Button>
            </div>
            {wallet.isConnected() !== true ? (
              <h3 style={{ textAlign: "center" }}>Wallet not connected.</h3>
            ) : (
              <div className="d-flex f-center mint-btn-field">
                <Button className="nft-mint-btn" onClick={nftMint}>
                  Mint
                </Button>
              </div>
            )}
          </div>
          {/* <Card className='nft-minting-card-container'>
            <Card.Img className='nft-img' variant="top" src={NftImage} />
            <Card.Body>
              <Card.Title><h2 className='nft-name'>Duck NFT</h2></Card.Title>
              <div>
                <p className='nft-mint-number'>5 / 1000 NFTs Minted</p>
                <p className='nft-mint-price'>0.08 ETH per NFT</p>
                <div className='d-flex f-center'>
                  <Button className="nft-mint-minus" disabled={mintNum === 0} onClick={mintNumMinus}>-</Button>
                  {console.log("mintNumber", mintNum)}
                  <Button className="nft-mint-amount">{mintNum}</Button>
                  <Button className="nft-mint-plus" onClick={mintNumPlus}>+</Button>
                </div>
              </div>
              {wallet.isConnected() !== true ? (
                <h3 style={{ textAlign: "center" }}>Wallet not connected.</h3>
              ) : (
                <div className='d-flex f-center'>
                  <Button className="nft-mint-btn">Mint</Button>
                </div>
              )}
            </Card.Body>
          </Card> */}
        </Col>
        <Col lg="3" md="3" sm="12" xs="12" className="nft-image-field">
          <img src={RightNFT} className="nft-image" />
        </Col>
      </Row>
      <Row className="desc-field">
        <Col lg="12" md="12" sm="12" xs="12">
          <p className="t-center desc-content">
            Please make sure you are connected to the right network (ETH
            Mainnet) and the correct address. Please note: Once you make the
            purchase, you cannot undo this action.
          </p>
          <p className="t-center desc-content">
            We have set the gas limit to 285000 For the contract to successfully
            mint your NFT. We recommend that you don't lower the gas limit.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
