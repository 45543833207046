import React, { useState, useEffect, useCallback, useMemo } from "react";
import "../../assets/css/style.css";
import { useWallet } from "use-wallet";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import LeftNFT from "../../assets/images/nft1.jpg";
import RightNFT from "../../assets/images/nft2.jpg";
import { healNFTClaim, healNFTContract } from "../../heal-contract";
import axios from "axios";

export default function ClaimingCard() {
  const [totalSupply, setTotalSupply] = useState(0);
  const [hasClaimed, setHasClaimed] = useState(false);
  const [root, setRoot] = useState(null);
  const [proofObj, setProofObj] = useState({
    index: null,
    amount: null,
    proof: null,
  });

  const wallet = useWallet();

  const getTotalSupply = async () => {
    const provider = wallet._web3ReactContext.library;
    const contract = await healNFTContract(provider);
    const value = await contract.methods.totalSupply().call();
    setTotalSupply(value);
  };

  const getHasClaimed = async () => {
    const provider = wallet._web3ReactContext.library;
    const contract = await healNFTContract(provider);
    const value = await contract.methods.claims(wallet.account).call();
    setHasClaimed(value);
  };

  const getProofHashData = async () => {
    const provider = wallet._web3ReactContext.library;
    const contract = await healNFTContract(provider);
    const hash = await contract.methods.proofHash().call();

    if (!hash) return undefined;
    const response = await axios.get(`https://ipfs.io/ipfs/${hash}`);
    if (!response?.data) return undefined;
    setRoot(response.data);

    const data = {
      merkleRoot: response.data ? response.data?.merkleRoot : "",
      totalCount: response.data ? response.data?.totalTotal : 0,
      claims: response.data ? response.data?.claims : {},
    };

    setProofObj({
      index: data.claims[wallet.account].index,
      amount: data.claims[wallet.account].amount,
      proof: data.claims[wallet.account].proof,
    });
  };

  const nftClaim = async () => {
    console.log(wallet.account, "Claim", proofObj);

    const provider = wallet._web3ReactContext.library;
    if (!proofObj.amount || !proofObj.index || !proofObj.proof) {
      toast.warning("No claim available yet!");
    } else {
      const transaction = healNFTClaim(provider, wallet.account, proofObj);
      await transaction
        .then(function (recipent) {
          toast.success("Successfuly claimed!");
        })
        .catch(function (e) {
          toast.error(e.message);
        });
    }
  };

  useEffect(() => {
    let mounted = true;

    if (mounted && wallet) {
      getTotalSupply();
      getHasClaimed();
      getProofHashData();
    }

    return () => {
      mounted = false;
    };
  }, [wallet]);

  console.log("hasClaimed >>> ", hasClaimed);
  console.log("root >>> ", root);
  console.log("proofObj >>> ", proofObj);

  return (
    <Container>
      <Row className="title-field">
        <Col lg="12" md="12" sm="12" xs="12">
          <div className="d-flex f-center">
            <h1 className="nft-page-title">$Heal NFTs</h1>
          </div>
        </Col>
      </Row>
      <Row className="main-field">
        <Col lg="3" md="3" sm="12" xs="12" className="nft-image-field">
          <img src={LeftNFT} className="nft-image" />
        </Col>
        <Col lg="6" md="6" sm="12" xs="12">
          <div className="nft-minting-card">
            <p className="nft-mint-number">{totalSupply} / 1000 NFTs Minted</p>
            <p className="nft-contract-address">
              0x3a3251Bf714F808C4aEe57F7D5f618C1d2612Ff0
            </p>
            <p className="nft-mint-price">
              {hasClaimed ? (
                <>Already claimed</>
              ) : (
                <>You can claim {proofObj.amount ?? 0} NFTs</>
              )}
            </p>
            {wallet.isConnected() !== true ? (
              <h3 style={{ textAlign: "center" }}>Wallet not connected.</h3>
            ) : (
              <div className="d-flex f-center mint-btn-field">
                <Button
                  className="nft-mint-btn"
                  disabled={
                    (!proofObj.amount || !proofObj.index || !proofObj.proof) &&
                    hasClaimed
                  }
                  onClick={nftClaim}
                >
                  Claim
                </Button>
              </div>
            )}
          </div>
        </Col>
        <Col lg="3" md="3" sm="12" xs="12" className="nft-image-field">
          <img src={RightNFT} className="nft-image" />
        </Col>
      </Row>
      <Row className="desc-field">
        <Col lg="12" md="12" sm="12" xs="12">
          <p className="t-center desc-content">
            Please make sure you are connected to the right network (ETH
            Mainnet) and the correct address. Please note: Once you make the
            purchase, you cannot undo this action.
          </p>
          <p className="t-center desc-content">
            We have set the gas limit to 285000 For the contract to successfully
            mint your NFT. We recommend that you don't lower the gas limit.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
