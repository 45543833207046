import "./css/transition.css";
import StakingPage from "./pages/StakingPage";
import Statistics from "./pages/ClaimPage";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { TransitionGroup } from "react-transition-group";
import DaoPage from "pages/DaoPage";
import NFTMintingPage from "pages/MintingPage";
import VideoIntro from "pages/VideoIntro";
import NFTClaimingPage from "pages/ClaimingPage";

export default function Main() {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition timeout={500} classNames="fade" key={location.key}>
        <Routes location={location}>
          <Route
            path="/Claim"
            element={<Statistics style={{ marginBottom: "50px" }} />}
          />
          <Route path="/Staking" element={<StakingPage />} />
          <Route path="/NFTClaiming" element={<NFTClaimingPage />} />
          <Route path="/NFTMinting" element={<NFTMintingPage />} />
          <Route path="/DAO" element={<DaoPage />} />
          <Route exact path="/" element={<VideoIntro />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}
